@import './../css/_variables.scss';
@font-face {
    font-family: "Lato";
    src: url("./../css/fonts/lato/Lato-Regular.ttf");
    font-weight: normal;
}
@font-face {
    font-family: "Lato";
    src: url("./../css/fonts/lato/Lato-Bold.ttf");
    font-weight: bold;
}

.container.chat{
    .chatbox {
        position: fixed;
        bottom: 0;
        right: 30px;
        width: 300px;
        height: 400px;
        background-color: #fff;
        font-family: 'Lato', sans-serif;
        -webkit-transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
        transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
        z-index: 13;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        box-shadow: 0px 6px 13px 0px #868686;
        border: solid 0px;
        border-top-right-radius: 0;
        border-top-left-radius: 20px;
        overflow: hidden;
    }
    
    .chatbox--tray {
        bottom: -360px;
        width: 270px;
    }
    
    .chatbox--closed {
        bottom: -400px;
    }
    
    .chatbox .form-control:focus {
        border-color: #1f2836;
    }
    
    .chatbox__title,
    .chatbox__body {
        border-bottom: none;
    }
    
    .chatbox__title {
        min-height: 40px;
        padding-right: 10px;
        background-color: $site_color_1;
        cursor: pointer;
    
        display: -webkit-flex;
        display: flex;
    
        -webkit-align-items: center;
        align-items: center;
    }
    
    .chatbox__title h5 {
        height: 40px;
        margin: 0 0 0 15px;
        line-height: 40px;
        position: relative;
        padding-left: 20px;
    
        -webkit-flex-grow: 1;
        flex-grow: 1;
    }
    
    .chatbox__title h5 span {
        color: #fff;
        max-width: 195px;
        display: inline-block;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 17px;
    }
    
    .chatbox__title h5:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 12px;
        height: 12px;
        background: #4CAF50;
        border-radius: 6px;
    
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    
    .chatbox__title__tray,
    .chatbox__title__close {
        width: 24px;
        height: 24px;
        outline: 0;
        border: none;
        background-color: transparent;
        opacity: 0.5;
        cursor: pointer;
    
        -webkit-transition: opacity 200ms;
        transition: opacity 200ms;
    }
    
    .chatbox__title__tray:hover,
    .chatbox__title__close:hover {
        opacity: 1;
    }
    
    .chatbox__title__tray span {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-bottom: 2px solid #fff
    }
    
    .chatbox__title__close svg {
        vertical-align: middle;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1.2px;
    }
    
    .chatbox__body,
    .chatbox__credentials {
        padding: 15px;
        border-top: 0;
        background-color: #f6ca9f59;
        -webkit-flex-grow: 1;
        flex-grow: 1;
    }
    
    .chatbox__credentials {
        display: none;
    }
    
    .chatbox__credentials .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .chatbox__body {
        overflow-y: auto;
    }
    
    .chatbox__body__message {
        position: relative;
        .default-image{
            background-color: $site_color_1;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        img{
            border: solid 1px $site_color_1;
            padding: 3px;
            background: white;
            box-sizing: border-box;
        }
    }
    
    .chatbox__body__message p {
        padding: 15px;
        border-radius: 4px;
        font-size: 14px;
        background-color: #fff;
        -webkit-box-shadow: 1px 1px rgba(100, 100, 100, 0.1);
        box-shadow: 1px 1px rgba(100, 100, 100, 0.1);
    }
    
    .chatbox__body__message{
        img, .default-image{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            // border: 2px solid #fcfcfc;
            position: absolute;
            top: 15px;
        }
    }
    
    .chatbox__body__message--left p {
        margin-left: 15px;
        padding-left: 30px;
        text-align: left;padding-top: 25px;
    }
    
    .chatbox__body__message--left{
        img, .default-image{
            left: -5px;
        }
    }
    
    .chatbox__body__message--right p {
        margin-right: 15px;
        padding-right: 30px;
        text-align: right;
    }
    
    .chatbox__body__message--right img {
        right: -5px;
    }
    
    .chatbox__message {
        padding: 15px;
        min-height: 50px;
        outline: 0;
        resize: none;
        border: none;
        font-size: 12px;
        border: 1px solid #ddd;
        border-bottom: none;
        background-color: #fefefe;
    }
    
    .chatbox--empty {
        height: 262px;
    }
    
    .chatbox--empty.chatbox--tray {
        bottom: -212px;
    }
    
    .chatbox--empty.chatbox--closed {
        bottom: -262px;
    }
    
    .chatbox--empty .chatbox__body,
    .chatbox--empty .chatbox__message {
        display: none;
    }
    
    .chatbox--empty .chatbox__credentials {
        display: block;
    }
    .chatbox_timing {
        position: absolute;
        right: 10px;
        font-size: 12px;
        top: 6px;
    }
    .chatbox_timing ul{padding:0;margin:0}
    .chatbox_timing ul li {
        list-style: none;
        display: inline-block;
        margin-left: 3px;
        margin-right: 3px;
    }
    .chatbox_timing ul li span{
        display:block;
        color:#747474;
        .fa-calendar{
            color: $site_color_1;
            margin-right: 4px;
        }
    }
    .ul_msg {
        padding: 10px !important;
    }
    .chatbox__body__message--right .ul_section_full{
        margin-right: 15px;
        padding-right: 30px;
        text-align: right;
        border-radius: 4px;
        font-size: 14px;
        background-color: #fff;
        -webkit-box-shadow: 1px 1px rgba(100, 100, 100, 0.1);
        box-shadow: 1px 1px rgba(100, 100, 100, 0.1);margin-bottom: 15px;
        padding-bottom: 5px;padding-top:15px;
    }
    .chatbox__body__message--left .ul_section_full {
        margin-left: 15px;
        padding-left: 15px;
        text-align: left;
        padding-top: 20px;
        padding-bottom: 5px;
        margin-bottom: 15px;
        border-radius: 4px;
        font-size: 14px;
        background-color: #fff;
        box-shadow: 1px 1px rgba(100, 100, 100, 0.1);
    }
    .ul_msg{padding:0;margin:0px}
    .ul_msg li{
        list-style:none;
        display:block; 
        line-height: 20px; 
        word-break: break-word;
    }
    .ul_msg2{padding:0;margin:0px;text-align: right;}
    .ul_msg2 li{list-style:none;display:inline-block;margin-right: 15px;}
    .chatbox__body__message--right .chatbox_timing  {
        left: 10px;
    }
    .chatbox__body__message--right .ul_msg2{text-align:left}
    .chatbox__body__message--right .ul_msg2 li {
        list-style: none;
        display: inline-block;
        margin-left: 15px;margin-right:0px
    }
    .chat_set_height {
        margin-top: 1px;
        border: none;
        font-size: 14px;
        flex-grow: 1;
        &:focus{
            outline: none;
            border: none;
        }
    }
    .chatbox22 .form-control:focus {
        border-color: white;
        box-shadow: none;
    }
    .width50{width:50%;float:left;background:#ECECEC;}
    /*======================Chat Box Ends=====================================================*/
    /*======================Message=====================================================*/
    .message_check{padding-top:10px;}
    .messsade_date {
        text-align: left;
        padding-top: 9px;
    }
    .messsade_date a{color:#000;}
    .padleftright0{padding-left:0px;padding-right:0px;}
    .message_box_area {
        color: #000;
        cursor: pointer;
    }
    .create_m {
        border: 1px solid #ccc !important;
    }
    .fileinput-button {
        float: left;
        margin-right: 4px;
        overflow: hidden;
        position: relative;
    }
    .fileinput-button {
        background: none repeat scroll 0 0 #eeeeee;
        border: 1px solid #e6e6e6;margin-top: 15px;
    }
    .fileinput-button {
        float: left;
        margin-right: 4px;
        overflow: hidden;
        position: relative;
    }
    .fileinput-button input {
        cursor: pointer;
        direction: ltr;
        font-size: 23px;
        margin: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(-300px, 0px) scale(4);
    }
    .fileupload-buttonbar .btn, .fileupload-buttonbar .toggle {
        margin-bottom: 5px;
    }
    .create_m:focus {
        border-color: #66afe9 !important;
        outline: 0 !important;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6) !important;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6) !important;
    }
    .col-lg-3.control-label {
        text-align: left;
    }
    .person-name{
        font-weight: bold;
    }
    // .panel-footer textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    //     font-size: 14px;
    // }
    // .panel-footer textarea::-moz-placeholder { /* Firefox 19+ */
    //     font-size: 14px;
    // }
    // .panel-footer textarea:-ms-input-placeholder { /* IE 10+ */
    //     font-size: 14px;
    // }
    .panel-footer{
        .input-wrapper{
            display: flex;
            justify-content: space-between;
            padding: 10px 0 10px 10px;
        }
        .send-message-btn{
            width: 40px;
            height: 40px;
            margin: 10px;
            border-radius: 50%;
            font-size: 16px;
            background: $site_color_2;
            border: none;
            color: white;
            cursor: pointer;
            transition: 0.3s;
            &:hover{
                background: #d55500;
            }
            &:focus{
                border: none;
                outline: none;
            }
        }
    }
}